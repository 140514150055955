 .image-col {
    display: flex;
    align-items: center;
  }
  
  .info-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .name {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .speech-bubble {
    background: #f8f8f8;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
  }
  