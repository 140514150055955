/* testimonials.css */
.glow-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: box-shadow 0.3s;
    height: 20rem;
  }
  
  .glow-container:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  }
  
  .glow-title {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .glow-text {
    font-size: 16px;
    color: #555;
  }
  